import { TimeBookingUser, User } from './user';

export interface CheckOut {
  id: number;
  checkedOutAt: string;
  checkIn: number;
  altitude: number | null;
  latitude: number | null;
  longitude: number | null;
}

export interface CheckIn {
  id: number;
  checkedInAt: string;
  checkOut?: number;
  altitude: number | null;
  latitude: number | null;
  longitude: number | null;
}

export interface TeamTimeBooking {
  id: number;
  contractorProject: number;
}

export interface TimeBooking {
  id: number;
  date: string;
  startedAt: string;
  finishedAt: string;
  checkIn?: number;
  totalMinutes: number;
  workMinutes: number;
  journeyMinutes: number;
  pauseMinutes: number;
  user: TimeBookingUser;
  contractorProject: number;
  createdBy?: User;
  modifiedBy?: User;
  teamBooking: number;
  uuid: string;
  createdAt: string;
  modifiedAt: string;
}

export interface AggregatedTimeBooking
  extends Pick<TimeBooking, 'journeyMinutes' | 'pauseMinutes' | 'workMinutes' | 'totalMinutes'> {
  aggregateDate: string;
  userCount: number;
}

export enum CheckInStatus {
  CHECKED_IN = 'checkedIn',
  NOT_CHECKED_IN = 'notCheckedIn',
  NEVER_CHECKED_IN = 'neverCheckedIn',
}

export enum TimeBookingAggregation {
  YEAR = 'year',
  QUARTER = 'quarter',
  MONTH = 'month',
  WEEK = 'week',
  DAY = 'day',
}
