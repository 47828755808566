import type { FC } from 'react';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, ButtonBase, Divider, Menu as BaseMenu, MenuItem, MenuProps, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import UserInfoAvatar from 'src/components/UserInfo/UserInfoAvatar/UserInfoAvatar';
import { LogoutReason } from 'src/contexts/types';
import routes from 'src/routes';
import { useUserStore } from 'src/services/auth/auth';
import { Permission, usePermissions } from 'src/services/auth/permissions';
import TestIDs from 'src/TestIDs.json';

import useStyles from './TopBarMenu.styles';

const TobBarMenu: FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const ref = useRef<any>(null);
  const { user, logout } = useUserStore();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState<boolean>(false);
  const { hasPermission } = usePermissions();

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout(LogoutReason.USER);
      navigate(routes.login.path);
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        variant: 'error',
      });
    }
  };

  const Menu = useCallback((props: Omit<MenuProps, 'open'>) => <BaseMenu {...props} open={isOpen} />, [isOpen]);

  const legalEntityTargetUrl = hasPermission(Permission.LEGAL_ENTITIES_CHANGE_LEGAL_ENTITY)
    ? routes.legalEntity.getLinkPath()
    : routes.storage.getLinkPath();

  return (
    <>
      <Box
        alignItems="center"
        component={ButtonBase}
        data-test-id={TestIDs.layouts.dashboardLayout.topBar.userDropDown}
        display="flex"
        onClick={handleOpen}
        // @ts-ignore
        ref={ref}
      >
        <UserInfoAvatar user={user} size="small" className={classes.avatarTextSpacing} />
        <Box sx={{ display: { md: 'block', xs: 'none' } }}>
          <Typography variant="h6" color="primary" className={classes.fullNameText}>
            {user.fullName}
          </Typography>
        </Box>
      </Box>
      <Menu
        data-test-id={TestIDs.layouts.dashboardLayout.topBar.userDropDownMenu}
        onClick={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        slotProps={{ paper: { className: classes.popover } }}
        anchorEl={ref.current}
      >
        <MenuItem
          component={RouterLink}
          to={routes.profile.getLinkPath()}
          data-test-id={TestIDs.layouts.dashboardLayout.topBar.userDropDownLinkProfile}
        >
          {t('Topbar.profile')}
        </MenuItem>
        {hasPermission(Permission.LEGAL_ENTITIES_VIEW_LEGAL_ENTITY) && (
          <MenuItem
            component={RouterLink}
            to={legalEntityTargetUrl}
            data-test-id={TestIDs.layouts.dashboardLayout.topBar.userDropDownLinkLegalEntity}
          >
            {t('Topbar.legalEntity')}
          </MenuItem>
        )}
        <Divider />

        <MenuItem onClick={handleLogout} data-test-id={TestIDs.layouts.dashboardLayout.topBar.userDropDownLogout}>
          {t('Topbar.logout')}
        </MenuItem>
      </Menu>
    </>
  );
};

export default TobBarMenu;
