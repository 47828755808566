import 'src/assets/css/global.css';

import { FC } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { StyledEngineProvider, Theme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { ThemeProvider as ThemeProviderLegacy } from '@mui/styles';
import { jssPreset, StylesProvider } from '@mui/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LicenseInfo } from '@mui/x-license';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { SnackbarProvider } from 'notistack';

import GlobalStyles from 'src/components/GlobalStyles/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset/ScrollReset';
import useSettings from 'src/hooks/useSettings';
import routes, { renderRoutes } from 'src/routes';
import { SUPPORTED_DATE_PICKER_TRANSLATIONS, SUPPORTED_LOCALES } from 'src/services/i18n/i18n';
import { createTheme } from 'src/theme';

import AnalyticsProvider from './contexts/AnalyticsContext';
import { UserStore, useUserStore } from './services/auth/auth';
import typography from './theme/typography';
import { MUI_LICENSE_KEY } from './constants';

LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const queryClient = new QueryClient();

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const Root: FC = () => {
  const { settings } = useSettings();
  const { i18n } = useTranslation();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {/* Temporary workaround to allow styles with newer versions of `mui` packages.
        https://github.com/mui/material-ui/issues/30092#issuecomment-1073041781
        TODO: Remove and migrate fully away from `adaptV4Theme` and into MUI 5 */}
        <ThemeProviderLegacy theme={theme}>
          <StylesProvider jss={jss}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={SUPPORTED_LOCALES[i18n.language]}
              localeText={
                SUPPORTED_DATE_PICKER_TRANSLATIONS[i18n.language].components.MuiLocalizationProvider.defaultProps
                  .localeText
              }
            >
              <SnackbarProvider maxSnack={3} style={{ fontFamily: typography.fontFamily }}>
                <QueryClientProvider client={queryClient}>
                  {/* @ts-ignore */}
                  <UserStore>
                    <AnalyticsProvider>
                      <GlobalStyles />

                      <ScrollReset />

                      <RoutesWrapper />
                    </AnalyticsProvider>
                  </UserStore>
                </QueryClientProvider>
              </SnackbarProvider>
            </LocalizationProvider>
          </StylesProvider>
        </ThemeProviderLegacy>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

/**
 * Wraps the renderRoutes function to be able to inject the user.
 */
const RoutesWrapper: FC = () => {
  const { user } = useUserStore();

  if (user) {
    Sentry.setUser({ id: `${user.id}`, email: user.email });
  }

  return renderRoutes(routes, user);
};

const router = createBrowserRouter([{ path: '*', Component: Root }]);
export default function App() {
  return <RouterProvider router={router} />;
}
