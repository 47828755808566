import { CommonInfoModel } from './generic';

export enum ReportStatus {
  QUEUED = 0,
  PROCESSING = 1,
  CREATED = 2,
  FAILED = -1,
}

export enum ReportKind {
  DAILY = 'daily',
}

export interface Report extends CommonInfoModel {
  id: string; // UUIDv4
  shortId: string;
  file?: string;
  name: string;
  status: ReportStatus;
  kind: ReportKind;
  startDate: string;
  endDate: string;
  contractorProject: number;
  size: number;
  owner: number;
}

export interface ReportCreatePayload {
  contractorProject: number;
  startDate: string;
  endDate: string;
  kind: ReportKind;
}
