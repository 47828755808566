import type { FC, ReactNode } from 'react';
import React, { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import LoadingScreen from 'src/components/LoadingScreen/LoadingScreen';
import { LogoutReason } from 'src/contexts/types';
import routes from 'src/routes';
import { useUserStore } from 'src/services/auth/auth';
import { Permission } from 'src/services/auth/permissions';

export interface BaseAuthGuardProps {
  children?: ReactNode;
}

const BaseAuthGuard: FC<BaseAuthGuardProps> = ({ children }) => {
  const { loggedIn, loading, user, logout, justLoggedOut } = useUserStore();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkForWebPermission = async () => {
      // If user does not have the permission to login to the webapp (anymore)
      // Logout and redirect
      if (user && !user.permissions?.includes(Permission.USERS_LOGIN_WEB)) {
        await logout();
        navigate(routes.login.path);
      }
    };

    checkForWebPermission();
  }, [user, history, logout]);

  if (!loggedIn && loading) {
    return <LoadingScreen />;
  }

  if (!loggedIn) {
    let redirectPath = routes.login.path;
    if (justLoggedOut !== LogoutReason.USER) {
      redirectPath = `${redirectPath}?next=${encodeURIComponent(
        `${location.pathname}${location.search}${location.hash}`,
      )}`;
    }
    return <Navigate replace to={redirectPath} />;
  }

  return <>{children}</>;
};

export default BaseAuthGuard;
