import { makeStyles } from '@mui/styles';

import type { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.drawer,
    boxShadow: '0 6px 10px 0 rgba(10, 34, 50, 0.08)',
    backgroundColor: 'white',
  },

  toolbar: {
    minHeight: 64,
  },
}));

export default useStyles;
