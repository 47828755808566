import { AxiosRequestConfig } from 'axios';

import { CurrentUser } from 'src/types';

export interface UserStoreValue {
  user: CurrentUser | null;
}

export enum LogoutReason {
  NONE,
  USER,
  AUTH,
}

export interface AuthFunctionContextValue {
  csrf: string;
  axiosRequestConfig: AxiosRequestConfig;
  loading: boolean;
  login: (email: string, password: string) => Promise<void>;
  loggedIn: boolean;
  logout: (reason?: LogoutReason) => Promise<void>;
  updateUserInfo: () => Promise<void>;
  justLoggedOut: LogoutReason;
}
