import { initReactI18next } from 'react-i18next';
import { deDE, enUS } from '@mui/x-date-pickers/locales';
import { de, enGB } from 'date-fns/locale';
import i18n from 'i18next';

import translationsDe from './languages/de/translations.json';
import translationsEn from './languages/en/translations.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: ['en'],
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    lng: 'de',
    resources: {
      de: translationsDe,
      en: translationsEn,
    },
  });

export default i18n;

export const SUPPORTED_LOCALES = { en: enGB, de };
export const SUPPORTED_DATE_PICKER_TRANSLATIONS = { en: enUS, de: deDE };
