import axios from 'axios';

import { API_URL } from 'src/constants';
import routes from 'src/routes';
import i18n from 'src/services/i18n/i18n';

const axiosInstance = axios.create({
  baseURL: API_URL,
});

const responseErrorHandler = (error: any) => {
  if (error?.response?.status === 401) {
    window.location.href = routes.login.path;
  }

  // Axios cancellation should be thrown and handled manually in any function that calls axios
  if (axios.isCancel(error)) {
    throw error;
  }

  return Promise.reject(error.response || error.response?.data || i18n.t('General.somethingWentWrong'));
};

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => responseErrorHandler(error),
);

export default axiosInstance;
