import { TFunction } from 'i18next';

import i18n from 'src/services/i18n/i18n';

/**
 * @description A function that converts bytes to human readable megabytes, kilobytes, etc.,
 * supports units up to terabytes and returns a number greater than 1000 after terabytes.
 * @param bytes The amount of bytes to convert
 * @param t The translation function
 * @returns An i18n formatted string with the converted bytes
 */
export const formatBytes = (bytes: number, t: TFunction) => {
  if (bytes === 0) return '0' + ' ' + t('General.fileSizes.b');

  const k = 1000;
  const sizes = ['b', 'kb', 'mb', 'gb', 'tb'];

  const i = Math.min(sizes.length - 1, Math.floor(Math.log(bytes) / Math.log(k)));

  const formattedAmount = Intl.NumberFormat(i18n.language).format(parseFloat((bytes / Math.pow(k, i)).toFixed(2)));

  return formattedAmount + ' ' + t(`General.fileSizes.${sizes[i]}`);
};

/**
 * Takes a first name and a last name and returns the initials of the names.
 *
 * If both names are given, the initials are created from the first letter of the first name and the first capital
 * letter of the last name.
 *
 * If only one of the two names is given, the initials are created from the given name in the following way:
 * - If the name consists of multiple space-separated parts, the initials are created from the first letters of the
 * first two parts.
 * - If the name consists of only one part, the initials are created from the first two letters.
 *
 * e.g. John Doe -> JD
 * e.g. John William Doe -> JW
 * e.g. John von Doe -> JD
 * e.g. Zantis von-Müller -> ZM
 * e.g. Dr. Gesa gen. Stemberg Pantaleon -> DS
 */
export const getInitials = (firstName?: string, lastName?: string): string => {
  const _firstName = firstName?.trim();
  const _lastName = lastName?.trim();
  if (_firstName && _lastName) {
    const lastNameInitial = _lastName.match(/\p{Lu}/u)?.[0] || _lastName[0];
    return `${_firstName[0]}${lastNameInitial}`.toUpperCase();
  }
  const name = _firstName || _lastName;
  if (!name) {
    return '';
  }
  const _splitParts = name.split(' ');
  return (_splitParts.length > 1 ? _splitParts.map((word) => word[0]).join('') : name).substring(0, 2).toUpperCase();
};

/**
 * Returns a human readable string for the given latitude or longitude values.
 * The values are fixed to 7 digits, which represents the pratical limitations of GPS.
 */
export const formattedCoordinates = (latitude: number, longitude: number) => {
  return `${latitude.toFixed(7)}, ${longitude.toFixed(7)}`;
};
