import { makeStyles } from '@mui/styles';

import type { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    width: 320,
  },

  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
}));

export default useStyles;
