import { makeStyles } from '@mui/styles';

import type { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    backgroundColor: theme.palette.primary.main,
  },
  small: {
    height: 32,
    width: 32,
    fontSize: '1rem',
  },
  normal: {
    height: 40,
    width: 40,
    fontSize: '1.2rem',
  },
}));

export default useStyles;
