import 'src/services/i18n/i18n';
import 'src/assets/css/prism.css';
import 'src/assets/css/lineclamp.css';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { enableMapSet } from 'immer';

import App from 'src/App';
import { API_URL, APP_VERSION, ENVIRONMENT, SENTRY_TRACE_SAMPLE_RATE, SENTRY_URL } from 'src/constants';
import { SettingsProvider } from 'src/contexts/SettingsContext';

enableMapSet();

if (SENTRY_URL) {
  Sentry.init({
    dsn: SENTRY_URL,
    environment: ENVIRONMENT,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: SENTRY_TRACE_SAMPLE_RATE,
    tracePropagationTargets: [API_URL],
    release: `wirbauen.digital-${APP_VERSION}`,
  });
}

createRoot(document.getElementById('root')).render(
  <SettingsProvider>
    <Sentry.ErrorBoundary>
      <App />
    </Sentry.ErrorBoundary>
  </SettingsProvider>,
);

// serviceWorker.register();
