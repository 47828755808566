import { Permission } from 'src/services/auth/permissions';

/**
 * Required permissions to retrieve and update the contractor configuration
 */
export const RETRIEVE_UPDATE_CONTRACTOR_CONFIGURATION_PERMISSIONS = [
  Permission.LEGAL_ENTITIES_CHANGE_LEGAL_ENTITY,
  Permission.LEGAL_ENTITIES_VIEW_LEGAL_ENTITY,
  Permission.LEGAL_ENTITIES_VIEW_CONTRACTOR_CONFIGURATION,
  Permission.LEGAL_ENTITIES_CHANGE_CONTRACTOR_CONFIGURATION,
];
