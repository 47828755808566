import { Trade } from './contractorProject';
import { DeviceInformation } from './deviceInformation';
import { PhotoCategory } from './photoCategory';
import { User } from './user';

export enum FileCaptureType {
  CAMERA = 'camera',
  GAEB = 'gaeb',
  GALLERY = 'gallery',
  UNSPECIFIED = 'unspecified',
}

export enum FileKind {
  IMAGE = 1,
  DOCUMENT = 2,
}
// NOTE: We could not just name this `File`, because this is already used by the JavaScript file API
export interface FileEntity {
  altitude: number | null;
  captureType: FileCaptureType;
  category: PhotoCategory;
  comment: string | null;
  createdAt: string;
  createdBy?: User;
  modifiedAt: string;
  modifiedBy?: User;
  deviceInformation: DeviceInformation | Record<string, unknown>;
  file: string;
  groupId: string | null;
  id: number;
  kind: FileKind;
  latitude: number | null;
  longitude: number | null;
  mimeType: string;
  name: string;
  recordedAt: string | null;
  size: number;
  thumbnail: string;
  uuid: string;
  owner: number;
  constructionProjects: NestedConstructionProject[];
  contractorProjects: NestedContractorProject[];
  tasks: number[];
  teamDayLaborSet: number[];
}

// Nests basic information about construction projects so that they do not need to be queried separately every time.
export interface NestedConstructionProject {
  id: number;
  name: string;
  trades: Trade[];
}

// The file has nested information about the contractor within the list of contractor projects
interface NestedContractor {
  id: number;
  name: string;
}

// The file contains nested information about the contractor project the file is also assigned to, e.g. because the
// contractor is the user and shared the picture with the general contractor or the other way around.
export interface NestedContractorProject {
  contractor: NestedContractor;
  trades: Trade[];
  id: number;
}

export interface DownloadFile {
  file: string;
  name: string;
}
