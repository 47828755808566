import { makeStyles } from '@mui/styles';

import type { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    width: 200,
  },
  fullNameText: {
    fontWeight: 'bold',
  },
  avatarTextSpacing: {
    marginRight: theme.spacing(1),
  },
}));

export default useStyles;
