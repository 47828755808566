import { colors } from '@mui/material';
import { makeStyles } from '@mui/styles';

import type { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    paddingTop: 0,
    paddingBottom: 0,
    display: 'block',
  },

  itemLeaf: {
    paddingTop: 0,
    paddingBottom: 0,
    display: 'flex',
  },

  button: {
    color: colors.common.white,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    padding: '10px 8px',
    justifyContent: 'flex-start',
  },

  buttonLeaf: {
    'color': colors.common.white,
    'fontWeight': theme.typography.fontWeightBold,
    'letterSpacing': 0,
    'textTransform': 'none',
    'width': '100%',
    'padding': '10px 8px',
    'justifyContent': 'flex-start',

    '&.depth-0': {
      '& $title': {
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  },

  icon: {
    color: colors.common.white,
    marginRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },

  title: {
    marginRight: 'auto',
  },

  active: {
    'color': theme.palette.accent.main,

    '& $title': {
      fontWeight: theme.typography.fontWeightBold,
    },

    '& $icon': {
      color: theme.palette.accent.main,
    },
  },
}));

export default useStyles;
