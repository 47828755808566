import type { FC, ReactNode } from 'react';
import React, { useRef, useState } from 'react';

import useScrollReset from 'src/hooks/useScrollReset';

import NavBar from './NavBar/NavBar';
import TopBar from './TopBar/TopBar';
import useStyles from './DashboardLayout.styles';

interface DashboardLayoutProps {
  children?: ReactNode;
}

const DashboardLayout: FC<DashboardLayoutProps> = ({ children }) => {
  const classes = useStyles();
  const contentRef = useRef();
  const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>(false);

  useScrollReset(contentRef.current);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />

      <NavBar onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />

      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content} ref={contentRef} id="content">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
