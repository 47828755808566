import type { FC, ReactNode } from 'react';
import React from 'react';
import { Navigate } from 'react-router-dom';

import routes from 'src/routes';
import { useUserStore } from 'src/services/auth/auth';
import { CurrentUser } from 'src/types';

import BaseAuthGuard from './BaseAuthGuard';

export interface AuthGuardWithCallbackProps {
  children?: ReactNode;
  isPermitted?: (user: CurrentUser) => boolean;
}

export const AuthGuardWithCallback: FC<AuthGuardWithCallbackProps> = ({ children, isPermitted }) => {
  const { user } = useUserStore();

  // If user does not have the permission for the route show not found
  if (!!isPermitted && !isPermitted(user)) {
    return <Navigate replace to={routes.notFound.path} />;
  }

  return <>{children}</>;
};

export default (props: AuthGuardWithCallbackProps) => (
  <BaseAuthGuard>
    <AuthGuardWithCallback {...props} />
  </BaseAuthGuard>
);
