import { colors, PaletteColorOptions, responsiveFontSizes } from '@mui/material';
import {
  buttonClasses,
  checkboxClasses,
  inputBaseClasses,
  svgIconClasses,
  tabClasses,
  toggleButtonClasses,
  typographyClasses,
} from '@mui/material';
import {
  createTheme as createMuiTheme,
  PaletteOptions as BasePaletteOptions,
  Theme as MuiTheme,
  ThemeOptions as BaseThemeOptions,
} from '@mui/material/styles';
import type {
  Palette as MuiPalette,
  PaletteColor,
  TypeBackground as MuiTypeBackground,
} from '@mui/material/styles/createPalette';
import { deepmerge } from '@mui/utils';

import { THEMES } from 'src/constants';

import { softShadows } from './shadows';
import typography from './typography';

export interface TypeBackground extends MuiTypeBackground {
  dark: string;
}

export interface Palette extends MuiPalette {
  accent: PaletteColor;
  background: TypeBackground;
  gray: PaletteColor;
  error: PaletteColor;
}

export interface Theme extends MuiTheme {
  name: string;
  palette: Palette;
}

type Direction = 'ltr' | 'rtl';

interface ThemeConfig {
  direction?: Direction;
  responsiveFontSizes?: boolean;
  theme?: string;
}

interface PaletteOptions extends Omit<BasePaletteOptions, 'background'> {
  accent: PaletteColorOptions;
  gray: PaletteColorOptions;
  background: Partial<TypeBackground> & { dark: string };
}

interface ThemeOptions extends Omit<BaseThemeOptions, 'palette'> {
  name?: string;
  palette?: PaletteOptions;
}

const baseOptions: BaseThemeOptions = {
  direction: 'ltr',
  components: {
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          [`&.${checkboxClasses.checked}`]: {
            color: '#123e59',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          [`&.${buttonClasses.containedSecondary}`]: {
            'backgroundColor': colors.grey[300],

            '&:hover': {
              backgroundColor: colors.grey[400],
            },
          },
        },
        sizeLarge: {
          padding: '6px 18px',
        },
      },
    },

    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: 'rgba(18, 62, 89, 0.38)', // Primary color, reduced opacity

          [`&.${toggleButtonClasses.selected}`]: {
            color: '#123e59', // Primary color
          },
        },
      },
    },

    MuiCard: {
      styleOverrides: {
        root: {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'clip',
        },
      },
    },

    MuiCardHeader: {
      styleOverrides: {
        action: {
          marginTop: 'auto',
        },
      },
    },

    MuiCardActions: {
      styleOverrides: {
        root: {
          marginTop: 'auto',
        },
      },
    },

    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0,0,0,0.075)',
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        select: {
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },

    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden',
        },
      },
    },

    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 32,
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          [`&textarea.${inputBaseClasses.disabled}::placeholder`]: {
            color: 'rgba(0, 0, 0, 0.38)', // text.disabled color, couldn't find a way to get it programmatically
          },
        },
      },
    },

    MuiSvgIcon: {
      styleOverrides: {
        root: {
          [`&.${svgIconClasses.colorSecondary}`]: {
            color: colors.grey[600],
          },
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          [`&.${tabClasses.selected}`]: {
            get fontWeight() {
              return typography.fontWeightBold;
            },
          },
          '&:hover': {
            backgroundColor: colors.grey[100],
          },
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          [`& .${typographyClasses.body1}`]: {
            fontSize: 'inherit', // Fixes different default font-sizes when using the Typography component inside a table cell.
          },
        },

        head: {
          get fontWeight() {
            return typography.fontWeightBold;
          },
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: { backgroundImage: 'unset' },
      },
    },
  },

  typography,
};

const themesOptions: ThemeOptions[] = [
  {
    name: THEMES.LIGHT,

    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&::placeholder': {
              opacity: 1,
              color: colors.blueGrey[600],
            },
          },
        },
      },
    },

    palette: {
      mode: 'light',
      action: {
        active: '#123e59',
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white,
      },
      gray: {
        main: '#e7ebee',
      },
      primary: {
        main: '#123e59',
      },
      secondary: {
        main: '#68c9d0',
      },
      accent: {
        main: '#f5a623',
      },
      success: {
        main: colors.green[500],
      },
      warning: {
        main: colors.orange[500],
      },
      error: {
        main: '#f71c00',
      },
      text: {
        primary: '#123e59',
        secondary: '#546e7a',
      },
    },
    shadows: softShadows,
  },
];

export const createTheme = (config: ThemeConfig = {}): Theme => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(deepmerge(baseOptions, { ...themeOptions, direction: config.direction }));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme as Theme;
};
