import type { FC } from 'react';

import useStyles from './GlobalStyles.styles';

const GlobalStyles: FC = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
