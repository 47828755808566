import type { FC, ReactNode } from 'react';
import React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import routes from 'src/routes';
import { useUserStore } from 'src/services/auth/auth';

interface GuestGuardProps {
  children?: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const { loggedIn } = useUserStore();
  const [urlSearchParams] = useSearchParams();

  if (loggedIn) {
    // Initial Screen
    // Change this redirect to set initial screen after login
    const url = urlSearchParams.get('next') || routes.root.path;
    return <Navigate replace to={url} />;
  }

  return <>{children}</>;
};

export default GuestGuard;
