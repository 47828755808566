import { makeStyles } from '@mui/styles';

import type { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  mobileDrawer: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    width: 256,
  },

  mobileDrawerHeader: {
    backgroundColor: 'white',
  },

  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },

  divider: {
    borderColor: 'white',
    width: '85%',
    margin: '0 auto',
  },

  subHeader: {
    color: 'white',
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0.83,
    textTransform: 'uppercase',
  },

  legalEntityLogo: {
    maxHeight: 80,
    maxWidth: '100%',
  },
}));

export default useStyles;
