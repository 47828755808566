import React, { Context, createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import { AuthFunctionContextValue, LogoutReason, UserStoreValue } from 'src/contexts/types';
import { loginRequest, logoutRequest, meRequest } from 'src/services/api/api';
import { CurrentUser } from 'src/types';

export interface UserStoreProps {
  initialUser?: CurrentUser;
  initialCsrf?: string;
  children?: ReactNode;
}

const errorPromise = (): Promise<never> =>
  Promise.reject(
    new Error(
      'Could not find an Authentication User Store in the component tree! Make sure, that you included a UserStore component in your component tree and that you did not mix components from different configurations!',
    ),
  );

const AuthFunctionContext = createContext<AuthFunctionContextValue>({
  loading: false,
  csrf: '',
  axiosRequestConfig: {},
  login: errorPromise,
  loggedIn: false,
  logout: errorPromise,
  updateUserInfo: errorPromise,
  justLoggedOut: LogoutReason.NONE,
});

interface MakeGenericUserStoreResult {
  UserContext: Context<UserStoreValue>;
  UserStore: React.FC<React.PropsWithChildren<UserStoreProps>>;
  useUserStore: () => UserStoreValue & AuthFunctionContextValue;
}

export function makeUserStore(): MakeGenericUserStoreResult {
  const UserContext = createContext<UserStoreValue>({ user: null });

  const UserStore: React.FC<React.PropsWithChildren<UserStoreProps>> = ({ children, initialCsrf, initialUser }) => {
    const [loggedOut, setLoggedOut] = useState<LogoutReason>(LogoutReason.NONE);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState<CurrentUser | null>(initialUser ?? null);
    const [csrf, setCsrf] = useState(initialCsrf ?? '');

    const axiosRequestConfig = useMemo(
      () => ({
        withCredentials: true,
        // Axios will merge this with the default headers, see axios/lib/core/Axios.js line 64 ff.
        headers: {
          'X-CSRFToken': csrf,
          'content-type': 'application/json',
        },
      }),
      [csrf],
    );

    const login = async (email: string, password: string) => {
      setLoading(true);

      try {
        const loginData = await loginRequest({ email, password, config: axiosRequestConfig });
        setUser(loginData.data.user);
        setCsrf(loginData.data.csrf);
      } finally {
        setLoading(false);
      }
    };

    const logout = async (reason = LogoutReason.USER) => {
      setLoading(true);

      try {
        const logoutData = await logoutRequest({ config: axiosRequestConfig });
        setUser(null);
        setCsrf(logoutData.data.csrf);
        setLoggedOut(reason);
      } finally {
        setLoading(false);
      }
    };

    const updateUserInfo = async () => {
      setLoading(true);

      try {
        const meData = await meRequest({ config: axiosRequestConfig });
        setUser(meData.data.user);
        setCsrf(meData.data.csrf);
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      // If we don't have a user, we need to obtain it via the me endpoint
      if (!user) {
        updateUserInfo();
      }
    }, []);

    return (
      <UserContext.Provider
        value={{
          user,
        }}
      >
        <AuthFunctionContext.Provider
          value={{
            csrf,
            axiosRequestConfig,
            loading,
            login,
            loggedIn: user !== null,
            logout,
            updateUserInfo,
            justLoggedOut: loggedOut,
          }}
        >
          {children}
        </AuthFunctionContext.Provider>
      </UserContext.Provider>
    );
  };

  const useUserStore: () => UserStoreValue & AuthFunctionContextValue = () => ({
    ...useContext(UserContext),
    ...useContext(AuthFunctionContext),
  });

  return {
    UserStore,
    useUserStore,
    UserContext,
  };
}
