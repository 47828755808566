import { FC, ReactNode, useMemo } from 'react';
import React from 'react';
import { useLocation } from 'react-router-dom';
import MatomoTracker from '@jonkoops/matomo-tracker';
import { createInstance, MatomoProvider } from '@jonkoops/matomo-tracker-react';

import { ANALYTICS_BASE_URL, ANALYTICS_SECURE_COOKIE, ANALYTICS_SITE_ID } from 'src/constants';
import useEffectAfterMount from 'src/hooks/useEffectAfterMount';

export interface AnalyticsProviderProps {
  children?: ReactNode;
}

const AnalyticsProvider: FC<AnalyticsProviderProps> = ({ children }) => {
  const location = useLocation();

  const createAnalyticsInstance = () => {
    if (ANALYTICS_BASE_URL === '' || ANALYTICS_SITE_ID === '') {
      console.log('Analytics disabled.');
      return;
    }

    return createInstance({
      urlBase: ANALYTICS_BASE_URL,
      siteId: ANALYTICS_SITE_ID,
      linkTracking: false, // Required for SPA tracking, we use enableLinkTracking()
      configurations: {
        // We are running Matomo in consentless-tracking mode without any cookies and an Opt-out by the user.
        // See the following link here for more information on the topic:
        // https://matomo.org/faq/new-to-piwik/how-do-i-use-matomo-analytics-without-consent-or-cookie-banner/
        // If we ever decide to track user behavior, we need to implement a cookie banner and request consent.
        // See here: https://developer.matomo.org/guides/tracking-consent for more details on how to realize that.
        disableCookies: true,
        setSecureCookie: ANALYTICS_SECURE_COOKIE,
        setRequestMethod: 'POST',
      },
    });
  };

  const analyticsInstance = useMemo<MatomoTracker>(() => createAnalyticsInstance(), []);

  useEffectAfterMount(() => {
    // track page view on each location change
    analyticsInstance?.enableLinkTracking(true);
    analyticsInstance?.trackPageView();
  }, [location.pathname]);

  return <MatomoProvider value={analyticsInstance}>{children}</MatomoProvider>;
};

export default AnalyticsProvider;
