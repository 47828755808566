import type { FC, ReactNode } from 'react';
import React from 'react';
import { Navigate } from 'react-router-dom';

import routes from 'src/routes';
import { Permission, usePermissions } from 'src/services/auth/permissions';

import BaseAuthGuard from './BaseAuthGuard';

export interface AuthGuardProps {
  children?: ReactNode;
  permissions?: Permission[];
}

const AuthGuard: FC<AuthGuardProps> = ({ children, permissions }) => {
  const { hasPermissions } = usePermissions();

  // If user does not have the permission for the route show not found
  if (permissions && !hasPermissions(permissions)) {
    return <Navigate replace to={routes.notFound.path} />;
  }

  return <>{children}</>;
};

export default (props: AuthGuardProps) => (
  <BaseAuthGuard>
    <AuthGuard {...props} />
  </BaseAuthGuard>
);
