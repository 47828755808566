export enum PhotoCategory {
  APPROVAL = 'approval',
  GENERAL = 'general',
  WORKING_TIME = 'working_time',
  OBSTRUCTION = 'obstruction',
  WORK_SAFETY = 'work_safety',
  FOREIGN_DEFECT = 'foreign_defect',
  DELIVERY_NOTE = 'delivery_note',
  COMPLAINT = 'complaint',
  DAY_LABOR_WORK = 'day_labor_work',
  OTHER = 'other',
}
